<template>
  <div class="influencers-search-switch">
    <div
      class="switch-part left-part"
      @click="setValue('keyword')"
      :class="{ selected: selectedValue == 'keyword' }"
    >
      Keyword
    </div>
    <div
      class="switch-part right-part"
      @click="setValue('username')"
      :class="{ selected: selectedValue == 'username' }"
    >
      Username
    </div>
  </div>
</template>


<script>
export default {
  name: "influencers-search-switch",
  props: {
    value: String
  },
  data() {
    return {
      selectedValue: this.value
    };
  },
  methods: {
    setValue(value) {
        this.selectedValue = value;
        this.$emit("input", this.selectedValue);
    }
  }
};
</script>


<style lang="scss">
.influencers-search-switch {
  display: flex;
  height: 40px;
  line-height: 35px;

  .switch-part {
    flex: 0 0 50%;
    text-align: center;

    cursor: pointer;
    background-color: #fff;

    &.selected {
      background: #80d9ff80 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 0px 4px 2px #0000000e;
      cursor: initial;
    }

    &.left-part {
      border-radius: 20px 0px 0px 20px;
      border: 2px solid #fff;
    }

    &.right-part {
      border-radius: 0px 20px 20px 0px;
      border: 2px solid #fff;
    }
  }
}
</style>