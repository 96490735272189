<template>
  <div class="bulk-sending-container">
    <div class="select-message-block">
      <div class="block-header">Select a message</div>
      <div class="block-content">
        <div class="message-content-radio"></div>
        <div class="mb-2 flex-middle">
          <label class="mr-2 mb-0">From</label>
          <multiselect
            v-if="fromOptions"
            placeholder="Select your email"
            v-model="userEmail"
            label="address"
            :allow-empty="false"
            :hide-selected="true"
            :disabled="false"
            track-by="address"
            :multiple="false"
            :options="fromOptions"
          >
            <template slot="singleLabel" slot-scope="props"
              ><img
                class="icon-size16 mr-2"
                :src="availableEmails[props.option.name].image"
                :alt="availableEmails[props.option.name].name"
              /><span class="option__desc"
                ><span class="option__title">{{
                  props.option.address
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="d-flex">
                <img
                  class="icon-size16 mr-2"
                  :src="availableEmails[props.option.name].image"
                  :alt="availableEmails[props.option.name].name"
                />
                <div class="option__desc">
                  <span class="option__title">{{ props.option.address }}</span>
                </div>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="message-subject flex-middle mb-2">
          <label class="mr-2 mb-0">Subject</label>
          <base-input
            class="mb-0 w-100"
            v-model="messageSubject"
            placeholder="No subject"
          ></base-input>
        </div>
        <div class="message-editor">
          <quill-editor
            ref="emailQuillEditor"
            v-model="messageContent"
          ></quill-editor>
        </div>
      </div>
    </div>

    <div class="select-campaign-block">
      <div class="block-header">Select a campaign</div>
      <div class="block-content">
        <div class="campaigns-wrapper">
          <div
            v-for="campaign in allCampaigns"
            :key="campaign.id"
            class="campaign-checkbox"
          >
            <base-checkbox
              @change="selectCampaign(campaign)"
              :checked="campaign.selected"
            >
              {{ campaign.name }}
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="select-stage-block">
      <div class="block-header">Select a status</div>
      <div class="block-content">
        <div class="stages-wrapper">
          <div
            v-for="stage in allStages"
            :key="stage.name"
            class="stage-checkbox"
          >
            <base-checkbox
              @change="selectStage(stage)"
              :checked="stage.selected"
            >
              {{ stage.name }}
            </base-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="select-lead-block">
      <div class="block-header">Select an influencer</div>
      <div class="block-content">
        <div class="search-info" v-if="allLeads.length == 0"></div>
        <div class="search-info" v-else>
          You can search and add from your total {{ allLeads.length }} leads
          (with {{ allLeadEmails.length }} email addresses) or select all of
          them
        </div>
        <div class="search-send flex-between">
          <div class="search-input">
            <div ref="search-input">
              <base-input
                class="search-input mr-3 mb-0"
                inputClasses=""
                placeholder="Search for influencers to add..."
                v-model="searchKeywords"
                @focus="searchFocus"
                @blur="searchBlur"
                :disabled="isAllLeadsSelected"
                type="search"
              >
                <!-- <div slot="addonRight">
            <base-button icon="fas fa-search" class="btn-black"> </base-button>
          </div> -->
              </base-input>
            </div>
            <div
              class="influencers-popover"
              role="tooltip"
              ref="show-influencers-popup"
            >
              <div
                class="influencers-popover-content"
                ref="show-influencers-popup"
              >
                <div
                  class="max-results-tip"
                  v-if="filteredLeads.length >= maxSearchResult"
                >
                  (only first {{ maxSearchResult }} influencers)
                </div>
                <div
                  class="influencer-item"
                  v-for="lead in filteredLeads"
                  :key="lead.id"
                  @click="selectLead(lead)"
                >
                  <img
                    :src="lead.profile_pic"
                    :alt="lead.username"
                    class="avatar avatar-sm"
                    @error="onLogoImageError($event)"
                  />
                  {{ (lead.data && lead.data.full_name) || lead.username }}
                </div>
              </div>
            </div>
          </div>
          <div class="or-divider">or</div>
          <div class="select-all-leads">
            <base-checkbox
              @change="selectAllLeads()"
              :checked="isAllLeadsSelected"
            >
              Select all influencers
            </base-checkbox>
          </div>
          <div class="send-button">
            <base-button
              class="btn-black m-1"
              :disabled="selectedLeadsEmails.length == 0 || bulkLeadsOverLimit"
              :isBusy="loading.send"
              @click="sendToLeads()"
            >
              Send to {{ selectedLeadsEmails.length }} recipients
            </base-button>
          </div>
        </div>
        <div class="warn-leads-over-limit p-2" v-if="bulkLeadsOverLimit">
          The maximum number of recipients is
          {{ bulkMaxRecipientsCount }} recepients. Please review and remove
          influencers from your selections.
        </div>
      </div>
    </div>
    <div class="selected-leads-block">
      <div class="block-header">Selected influencers</div>
      <div class="block-content">
        <div class="leads-list" v-if="selectedLeads.length">
          <div class="lead-item" v-for="lead in selectedLeads" :key="lead.id">
            <div class="remove-button" @click="removeLead(lead)">
              <span class="material-icons"> cancel </span>
            </div>
            <img
              :src="lead.profile_pic"
              :alt="lead.username"
              class="avatar avatar-sm"
              @error="onLogoImageError($event)"
            />
            <div class="lead-name text-truncate">
              {{ (lead.data && lead.data.full_name) || lead.username }}
            </div>
          </div>
        </div>
        <div class="leads-list-empty text-center p-3" v-else>
          Select influencers with the searchbox above or select all the leads
          with checkbox
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { createPopper } from "@popperjs/core";
import Multiselect from "vue-multiselect";

import { mapState, mapGetters } from "vuex";

import { MESSAGES, AVAILABLE_EMAILS } from "@/constants";
import { onLogoImageError, wrapEmailContentToHtmlPage } from "@/utils";

export default {
  name: "bulk-sending",
  components: {
    quillEditor,
    Multiselect
  },
  data() {
    return {
      userEmail: null,
      availableEmails: AVAILABLE_EMAILS,
      //
      messageSubject: "",
      messageContent: "",

      loading: {
        send: false
      },

      popperInstance: null
    };
  },
  computed: {
    ...mapState({
      bulkSending: (state) => state.workspace.bulkSending,
      allCampaigns: (state) => state.workspace.bulkSending.campaigns,
      isAllLeadsSelected: (state) =>
        state.workspace.bulkSending.isAllLeadsSelected,
      selectedLeads: (state) => state.workspace.bulkSending.selectedLeads,
      maxSearchResult: (state) => state.workspace.bulkSending.maxSearchResult,
      fromOptions: (state) => {
        if (!state.integration.emails) return [];
        return [
          ...(state.integration.emails.outlook
            ? state.integration.emails.outlook.map((e) => {
                return { address: e.address, name: "outlook" };
              })
            : []),
          ...(state.integration.emails.gmail
            ? state.integration.emails.gmail.map((e) => {
                return { address: e.address, name: "gmail" };
              })
            : [])
        ];
      }
    }),
    ...mapGetters({
      allStages: "workspace/bulkCampaignsStages",
      allLeads: "workspace/allLeads",
      allLeadEmails: "workspace/allLeadEmails",
      selectedLeadsEmails: "workspace/selectedLeadsEmails",
      filteredLeads: "workspace/filteredLeads",
      bulkMaxRecipientsCount: "workspace/bulkMaxRecipientsCount",
      bulkLeadsOverLimit: "workspace/bulkLeadsOverLimit"
    }),
    editor() {
      return this.$refs.emailQuillEditor.quill;
    },
    searchKeywords: {
      set(value) {
        this.$store.dispatch("workspace/setBulkSearchKeywords", value.trim());
      },
      get() {
        return this.bulkSending.searchKeywords;
      }
    }
  },
  watch: {
    async allCampaigns(val) {
      if (val.length > 0) {
        await this.$store.dispatch("workspace/fetchAllLeads");
      }
    }
  },
  async mounted() {
    this.$nextTick(async function () {
      await this.$store.dispatch("workspace/getCampaigns");
      await this.$store.dispatch("integration/getConnectedEmails");
    });
  },
  methods: {
    onLogoImageError: onLogoImageError,
    async selectCampaign(campaign) {
      await this.$store.dispatch("workspace/selectCampaign", {
        campaignId: campaign.id,
        selectValue: !campaign.selected
      });
    },
    async selectStage(stage) {
      await this.$store.dispatch("workspace/selectStage", {
        stageName: stage.name,
        selectValue: !stage.selected
      });
    },
    async selectAllLeads() {
      await this.$store.dispatch("workspace/selectAllLeads", {
        selectValue: !this.isAllLeadsSelected
      });
    },
    async removeLead(lead) {
      await this.$store.dispatch("workspace/removeLead", {
        leadId: lead.id
      });
    },
    async showInfluencersPopup(event) {
      if (this.popperInstance) return;

      const showInfluencersPopup = this.$refs["show-influencers-popup"];
      const searchInput = this.$refs["search-input"];
      this.popperInstance = createPopper(searchInput, showInfluencersPopup, {
        placement: "bottom-start",
        strategy: "absolute"
      });

      showInfluencersPopup.setAttribute("data-show", "");
      this.$nextTick(function () {
        this.popperInstance.update();
      });
      //this.influencersPopupVisible = true;
    },
    closeInfluencersPopup() {
      const showInfluencersPopup = this.$refs["show-influencers-popup"];
      showInfluencersPopup.removeAttribute("data-show");

      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = undefined;
      }

      //this.influencersPopupVisible = false;
    },

    searchFocus() {
      this.showInfluencersPopup();
    },
    searchBlur() {
      var self = this;
      setTimeout(function () {
        self.closeInfluencersPopup();
      }, 500);
    },
    async selectLead(lead) {
      this.closeInfluencersPopup();
      this.$store.dispatch("workspace/setBulkSearchKeywords", "");
      await this.$store.dispatch("workspace/selectLead", {
        leadId: lead.id
      });
    },
    async sendToLeads() {
      if (!this.messageContent) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["email__content-empty"],
          alertClass: "danger"
        });
        return;
      }

      if (!this.messageSubject) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["email__subject-empty"],
          alertClass: "danger"
        });
        return;
      }

      debugger;

      const toRecipients = [
        {
          name: this.userEmail.name,
          address: this.userEmail.address
        }
      ];

      let leadsIds = [];
      let bccRecipients = [];
      this.selectedLeads.forEach((lead) => {
        leadsIds.push(lead.id);
        let recipients = lead.data.emails.map((email) => {
          return {
            name: lead.data.full_name || email,
            address: email
          };
        });
        bccRecipients = bccRecipients.concat(recipients);
      });

      try {
        this.loading.send = true;
        var result = await this.$store.dispatch("inbox/sendEmail", {
          //console.log("Sending email message", {
          name: this.userEmail.name,
          address: this.userEmail.address,
          //lead_id: this.lead.id,
          lead_ids: leadsIds,
          send_meta: {
            subject: this.messageSubject,
            body:
              this.userEmail.name == "gmail"
                ? wrapEmailContentToHtmlPage(this.messageContent)
                : this.messageContent,
            to_recipients: toRecipients,
            //cc_recipients: this.cc,
            bcc_recipients: bccRecipients
          }
        });

        if (result && result.data && result.data.sent && result.data.message) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["email__bulk-send-messages-success"],
            alertClass: "success"
          });

          this.messageSubject = "";
          this.messageContent = "";
          await this.$store.dispatch("workspace/bulkReset");
        } else {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-error-200"],
            alertClass: "danger"
          });
        }

        this.loading.send = false;
      } catch {
        this.loading.send = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: "Ooops! Something went wrong.",
          alertClass: "danger"
        });
      }
    }
  }
};
</script>


<style lang="scss">
@import "../assets/scss/custom/functions";
@import "../assets/scss/custom/variables";

.bulk-sending-container {
  display: flex;
  flex-direction: column;

  .block-header {
    font-size: 26px;
  }

  .select-message-block {
    padding-top: 15px;
    position: relative;

    .ql-container {
      height: 200px;
      background-color: $white;
    }
  }

  .select-campaign-block {
    padding-top: 15px;
    .campaigns-wrapper {
      display: flex;
      flex-wrap: wrap;

      .campaign-checkbox {
        padding: 5px;
        margin: 5px;
        background-color: $white;
        border-radius: 5px;
        box-shadow: $form-input-shadow;
      }
    }
  }

  .select-stage-block {
    padding-top: 15px;
    .stages-wrapper {
      display: flex;
      flex-wrap: wrap;

      .stage-checkbox {
        padding: 5px;
        margin: 5px;
        background-color: $white;
        border-radius: 5px;
        box-shadow: $form-input-shadow;
      }
    }
  }

  .select-lead-block {
    padding-top: 15px;

    .search-info {
      padding: 10px 0px;
      text-align: center;
      color: $gray-800;
    }

    .search-input {
      width: 300px;
      position: relative;

      .influencers-popover {
        background-color: $white;
        z-index: 10;
        display: none;

        &[data-show] {
          display: block;
        }

        .influencers-popover-content {
          width: 300px;
          height: 200px;
          padding: 10px;
          overflow-y: auto;
          background-color: $white;

          .max-results-tip {
            color: $gray-600;
            font-size: 0.8em;
            text-align: center;
            font-style: italic;
          }

          .influencer-item {
            text-align: left;
            padding-bottom: 5px;

            cursor: pointer;
          }
        }
      }
    }

    .or-divider {
      padding: 0 15px;
      align-self: center;
      padding-bottom: 15px;
    }

    .select-all-leads {
      margin: 10px 0;
    }

    .send-button {
      flex: 1 1 auto;
      text-align: right;
    }

    .warn-leads-over-limit {
      text-align: center;
      color: $danger;
    }
  }

  .selected-leads-block {
    padding-top: 15px;
    .leads-list {
      min-height: 300px;
      display: flex;
      flex-wrap: wrap;

      .lead-item {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-direction: column;
        width: 100px;
        position: relative;

        align-items: center;

        .remove-button {
          position: absolute;
          top: 10px;
          right: 20px;
          display: none;
          cursor: pointer;
        }

        &:hover {
          .remove-button {
            display: inherit;
          }
        }

        .lead-name {
          text-align: center;
          width: 100%;
        }
      }
    }

    .leads-list-empty {
      min-height: 300px;
    }
  }
}
</style>