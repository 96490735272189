<template>
  <div
    class="modal edit-lead-detail-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>{{ lead.username }}</span>
          </a>
          <div v-if="lead" slot="center" class="title">
            Edit influencer
          </div>
        </modal-topbar>
        <div
          v-if="lead"
          class="modal-topbar--profile d-flex flex-wrap flex-column p-3"
        >
          <!-- instagram -->
          <template v-if="lead.social == socials.instagram.id">
            <div
              class="flex-column align-items-baseline justify-content-between flex-wrap w-100 my-2"
            >
              <div class="flex-between w-100">
                <div class="profile-avatar">
                  <div class="social-icon">
                    <img
                      :src="socials.instagram.image"
                      :alt="socials.instagram.name"
                    />
                  </div>
                  <div class="avatar mt-1">
                    <avatar
                      :username="
                        lead.data.full_name
                          ? lead.data.full_name
                          : lead.data.username
                      "
                      :size="32"
                      :src="leadPictureUrl"
                    >
                    </avatar>
                  </div>
                  <div class="flex-column w-100 ml-2">
                    <div class="profile-name-container d-flex align-items-baseline">
                      <div class="profile-name">
                        {{ lead.data.full_name }}
                      </div>
                      <div class="profile-username ml-2">
                        @{{ lead.username }}
                      </div>
                    </div>
                    <div class="profile-bio text-overflow-ellipsis">
                      {{ lead.data.biography }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- tiktok -->
          <template v-if="lead.social == socials.tiktok.id">
            <div
              class="flex-column align-items-baseline justify-content-between flex-wrap w-100 my-2"
            >
              <div class="flex-between w-100">
                <div class="profile-avatar">
                  <div class="social-icon">
                    <img
                      :src="socials.tiktok.image"
                      :alt="socials.tiktok.name"
                    />
                  </div>
                  <div class="avatar mt-1">
                    <avatar
                      :username="
                        lead.data.nickname ? lead.data.nickname : lead.username
                      "
                      :size="32"
                      :src="leadPictureUrl"
                    >
                    </avatar>
                  </div>
                  <div class="flex-column w-100 ml-2">
                    <div class="profile-name-container d-flex align-items-baseline">
                      <div class="profile-name">
                        {{ lead.data.nickname }}
                      </div>
                      <div class="profile-username ml-2">
                        @{{ lead.username }}
                      </div>
                    </div>
                    <div class="profile-bio text-overflow-ellipsis">
                      {{ lead.data.signature }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- youtube -->
          <template v-if="lead.social == socials.youtube.id">
            <div
              class="flex-column align-items-baseline justify-content-between flex-wrap w-100 my-2"
            >
              <div class="flex-between w-100">
                <div class="profile-avatar">
                  <div class="social-icon">
                    <img
                      :src="socials.youtube.image"
                      :alt="socials.youtube.name"
                    />
                  </div>
                  <div class="avatar mt-1">
                    <avatar
                      :username="
                        lead.data.title ? lead.data.title : lead.username
                      "
                      :size="32"
                      :src="leadPictureUrl"
                    >
                    </avatar>
                  </div>
                  <div class="flex-column w-100 ml-2">
                    <div class="profile-name-container d-flex align-items-baseline">
                      <div class="profile-name">
                        {{ lead.data.title }}
                      </div>
                      <div class="profile-username ml-2">
                        @{{ lead.username }}
                      </div>
                    </div>
                    <div class="profile-bio text-overflow-ellipsis">
                      {{ lead.data.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="modal-body main-content pt-0">
          <form @submit.prevent="saveLead">
            <div class="row">
              <div class="col-12">
                <base-input
                  class="input-group-alternative mb-4"
                  placeholder="https://site.url"
                  type="text"
                  v-model="newLead.data.website"
                  label="WEB SITE"
                ></base-input>
              </div>
              <template v-if="lead.social == socials.instagram.id">
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    v-model="newLead.data.followers"
                    label="FOLLOWERS"
                  ></base-input>
                </div>
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    step="any"
                    v-model="newEngagementRate"
                    label="ENGAGEMENT RATE (%)"
                  ></base-input>
                </div>
              </template>
              <template v-if="lead.social == socials.tiktok.id">
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    v-model="newLead.data.fans"
                    label="FANS"
                  ></base-input>
                </div>
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    step="any"
                    v-model="newEngagementRate"
                    label="ENGAGEMENT RATE (%)"
                  ></base-input>
                </div>
              </template>
              <template v-if="lead.social == socials.youtube.id">
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    v-model="newLead.data.subscribers"
                    label="SUBSCRIBERS"
                  ></base-input>
                </div>
                <div class="col-sm-6">
                  <base-input
                    class="input-group-alternative mb-4"
                    placeholder="0"
                    type="number"
                    step="any"
                    v-model="newEngagementRate"
                    label="ENGAGEMENT RATE (%)"
                  ></base-input>
                </div>
              </template>
              <div class="col-12">
                <base-input
                  class="input-group-alternative mb-4"
                  placeholder="street, city, state, ZIP code"
                  type="test"
                  v-model="newLead.data.address"
                  label="ADDRESS"
                ></base-input>
              </div>
              <div class="col-12">
                <label class="form-control-label">
                  EMAILS
                </label>
                <div
                  v-for="(email, index) in newLead.data.emails"
                  :key="index"
                  class="lead-email-container"
                >
                  <base-input
                    class="input-group-alternative mb-3"
                    placeholder="lead@mail.com"
                    type="email"
                    v-model="newLead.data.emails[index]"
                    @keydown.enter.prevent=""
                  ></base-input>
                  <a
                    class="btn-delete"
                    v-on:click="deleteEmail(index)"
                    title="Delete this email"
                    alt="Delete this email"
                  >
                    <i class="fas fa-times-circle"></i>
                  </a>
                </div>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="lead@mail.com"
                  type="email"
                  v-model="newEmail"
                  @blur="addNewEmail"
                  @keydown.enter.prevent="addNewEmail"
                ></base-input>
                <base-input
                  v-if="newEmail.length"
                  class="input-group-alternative"
                  placeholder="lead@mail.com"
                  type="email"
                ></base-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 flex-center">
                <base-button
                  :isBusy="loading.save"
                  :nativeType="'submit'"
                  type="primary"
                  class="btn-w-25"
                  >Save</base-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { SOCIALS } from "@/constants";
import Avatar from "vue-avatar";
import MESSAGES from "../constants/message";

export default {
  name: "edit-lead-detail-panel",
  components: {
    Avatar
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
  },
  data() {
    return {
      socials: SOCIALS,
      newLead: {
        data: {
          website: "",
          address: "",
          engagement_rate: 0
        }
      },
      newEmail: "",
      newEngagementRate: null,
      loading: {
        save: false
      },
      leadPictureUrl: null
    };
  },
  computed: {
    ...mapState({
      lead: (state) => state.campaign.lead,
    })
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-detail-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    getLeadPictureUrl(lead) {
      let img = new Image();
      img.onload = () => {
        this.leadPictureUrl = lead.profile_pic;
      };
      img.src = lead.profile_pic;
    },
    deleteEmail(index) {
      if ( typeof this.newLead.data.emails === "string") {

      }

      this.newLead.data.emails.splice(index, 1);
      this.newLead = { ...this.newLead };
    },
    addNewEmail() {
      if (this.newEmail) {
        // need to make email validation
        this.newLead.data.emails.push(this.newEmail);
        this.newLead = { ...this.newLead };
        this.newEmail = "";
      }
    },
    async saveLead() {
      this.loading.save = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/updateLead",
          this.newLead
        );
        this.loading.save = false;
        if (result) {
          this.closePanel();
        }
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
        this.loading.save = false;
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      this.leadPictureUrl = this.getLeadPictureUrl(this.lead);
      this.newLead = JSON.parse(JSON.stringify(this.lead));

      //sh.sergey - workaround for incorrect value "... allowed for premium toer ..." instead of emails array
      if ((this.newLead.data && !this.newLead.data.emails) || 
            typeof this.newLead.data.emails === "string") {
        this.newLead.data.emails = [];
      }
      this.newEngagementRate =
        Math.round((this.newLead.data.engagement_rate ||
          this.newLead.data.avg_engagement_rate) * 1000) / 10;
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-detail-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-detail-panel").scrollTop = 0;
      }
    },
    newEngagementRate(val) {
      if (this.newLead.social === SOCIALS.instagram.id) {
        this.newLead.data.engagement_rate = val / 100;
      } else {
        this.newLead.data.avg_engagement_rate = val / 100;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
