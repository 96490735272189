<template>
  <div
    class="modal edit-lead-pricing-panel right-sliding-panel"
    :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>{{currentProfile.full_name}}</span>
          </a>
          <div slot="center">Pricing</div>
        </modal-topbar>
         
        <div v-if="currentProfile" class="modal-body main-content--pricing">
          <ul class="panel-blocks mt-4">
            <li class="panel-item">
              <div class="mb-2 campaign-label mt-1">
                <span class=" nav-link-text ml-2">PRICE</span>
              </div>
              <base-input
                id="input_price"
                class="input-group-alternative mb-3"
                placeholder="0"
                type="text"
                autofocus
              ></base-input>
            </li>
            <li class="panel-item">
              <div class="mb-2 campaign-label">
                <span class=" nav-link-text ml-2">NUMBER OF POSTS</span>
              </div>
              <base-input
                class="input-group-alternative mb-3"
                placeholder="0"
                type="text"
              ></base-input>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "edit-lead-pricing-panel",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    }
  },
  data() {
    return {
      loading: {
        profile: false
      },
      currentProfile: {
        username: "cristiano",
        full_name: "Cristiano Ronaldo",
        profile_pic_url:
          "https://scontent-iad3-1.cdninstagram.com/vp/994271c72e7f70706b0850a226a7dfa2/5DB94318/t51.2885-19/s150x150/55837776_379384389454688_4456240822588801024_n.jpg?_nc_ht=scontent-iad3-1.cdninstagram.com"
      },

      localError: false
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-pricing-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    selectCard(type, param) {
      this.$emit("selectCard", {type: type, param: param});
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-pricing-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-pricing-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
