<template>
  <div
    class="modal export-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content pt-md-0">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>Back</span>
          </a>
          <div slot="center">Export Influencers Data</div>
        </modal-topbar>

        <div class="modal-body">
          <tabs :pills="false" @setActiveTab="resizeWindow()" v-if="exportList.length">
            <template v-for="(social, k) in socials">
              <tab-pane
                v-if="hasSocialInExportList(social.id)"
                :key="k"
                :id="social.id"
                :title="social.name"
                :icon="social.image"
              >
                <!-- instagram -->
                <template v-if="social.id == socials.instagram.id">
                  <ul class="pannel-blocks mt-5">
                    <li class="panel-item profile-block">
                      <h3>Review the Instagram profiles you want to export</h3>

                      <VueScrollableTable
                        class="profile-export-table table-instagram"
                        :scroll-horizontal="true"
                        :scroll-vertical="true"
                        :sync-header-scroll="true"
                        :dead-area-color="'transparent'"
                        :class="{ freezeFirstColumn: true }"
                      >
                        <template slot="thead">
                          <tr>
                            <th
                              :class="
                                'checkbox-column ' +
                                  (enabledCheckAllProfiles
                                    ? 'checkbox-column-checked'
                                    : '')
                              "
                            >
                              <base-checkbox
                                v-model="enabledCheckAllProfiles"
                                class="custom-control-alternative"
                                @change="checkAllProfiles"
                              ></base-checkbox>
                            </th>
                            <th class="name-column">NAME</th>
                            <th>FOLLOWERS</th>
                            <th>ENGAGEMENT</th>
                            <th>AVG LIKES PER POST</th>
                            <th>AVG REPLIES PER POST</th>
                            <th>AVG VIEWS PER VIDEO</th>
                          </tr>
                        </template>
                        <template slot="tbody">
                          <template v-for="(row, index) in exportList">
                            <tr
                              v-if="row.social == social.id"
                              :key="index"
                              class="d-flex"
                              :style="{
                                width: tableHeaderWidth[social.id] + 'px'
                              }"
                            >
                              <th
                                :class="
                                  'checkbox-column ' +
                                    (includeExport[index]
                                      ? 'checkbox-column-checked'
                                      : '')
                                "
                              >
                                <base-checkbox
                                  v-model="includeExport[index]"
                                  class="custom-control-alternative"
                                  @change="checkProfile"
                                ></base-checkbox>
                              </th>
                              <td
                                @click="showProfile(row)"
                                class="name-column flex-middle"
                              >
                                <!-- <div class="avatar ml-2">
                                  <img
                                    :src="row.profile_pic_url"
                                    itemprop="image"
                                    :alt="row.first_name"
                                    :title="row.first_name"
                                    @error="onLogoImageError($event)"
                                  />
                                </div> -->
                                <div class="flex-column ml-2">
                                  <div class="profile-name">
                                    {{ getFullName(row) }}
                                  </div>
                                  <div class="profile-username">
                                    @{{ row.username }}
                                  </div>
                                </div>
                              </td>
                              <td class="space-column"></td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.followers | BigNumber }}
                              </td>
                              <td>
                                <div
                                  class="flex-column align-items-center"
                                >
                                  <div class="completion">
                                    {{
                                      row.engagement_rate | Percent
                                    }}
                                  </div>
                                  <div class="w-100 px-1">
                                    <base-progress
                                      type="primary"
                                      :show-percentage="false"
                                      :class="
                                        'pt-0 pt-' +
                                          getEngagementRateClass(row)
                                      "
                                      :value="
                                        engagementRateChartList[
                                          getEngagementRateClass(row)
                                        ].percent
                                      "
                                    />
                                  </div>
                                </div>
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{
                                  row.likes_per_post | BigNumber
                                }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{
                                  row.replies_per_post | BigNumber
                                }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{
                                  row.views_per_video | BigNumber
                                }}
                              </td>
                            </tr>
                          </template>
                        </template>
                      </VueScrollableTable>
                    </li>
                    <li class="panel-item field-block my-3">
                      <h3>Select which fields to export</h3>
                      <div class="field-wrapper p-3">
                        <div
                          v-for="(category, cIndex) in fieldCategories[social.id]"
                          :key="social.id + '#' + category.name"
                          class="category-block"
                        >
                          <div class="category d-flex py-2">
                            <a
                              :class="
                                'mr-3 category-collapse category-' +
                                  (category.open ? 'open' : 'close')
                              "
                              @click="toggleCategory(cIndex, social.id)"
                            >
                              <i class="fas fa-chevron-right"></i>
                            </a>
                            <base-checkbox
                              v-model="category.selected"
                              @change="updateTreeByCategory(cIndex, social.id)"
                              class="ml-2"
                              >{{ category.label }}</base-checkbox
                            >
                          </div>
                          <div
                            class="field-container"
                            :style="{
                              height: category.open
                                ? getNumberOfField(category, social.id) * 25 +
                                  'px'
                                : '0px'
                            }"
                          >
                            <div
                              v-for="field in fields"
                              :key="field.social + '#' + field.name"
                              class="field"
                            >
                              <div
                                v-if="
                                  field.category == category.name &&
                                    field.social == social.id
                                "
                                class="d-flex ml-5"
                                :style="{ height: '25px' }"
                              >
                                <div class="field-vertical-line mr-4"></div>
                                <base-checkbox
                                  v-model="field.selected"
                                  @change="updateTreeByField(cIndex, social.id)"
                                  >{{ field.label }}</base-checkbox
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
                <!-- tiktok -->
                <template v-if="social.id == socials.tiktok.id">
                  <ul class="pannel-blocks mt-5">
                    <li class="panel-item profile-block">
                      <h3>Review the TikTok profiles you want to export</h3>

                      <VueScrollableTable
                        class="profile-export-table table-tiktok"
                        :scroll-horizontal="true"
                        :scroll-vertical="true"
                        :sync-header-scroll="true"
                        :dead-area-color="'transparent'"
                        :class="{ freezeFirstColumn: true }"
                      >
                        <template slot="thead">
                          <tr>
                            <th
                              :class="
                                'checkbox-column ' +
                                  (enabledCheckAllProfiles
                                    ? 'checkbox-column-checked'
                                    : '')
                              "
                            >
                              <base-checkbox
                                v-model="enabledCheckAllProfiles"
                                class="custom-control-alternative"
                                @change="checkAllProfiles"
                              ></base-checkbox>
                            </th>
                            <th class="name-column">NAME</th>
                            <th>FANS</th>
                            <th>ENGAGEMENT</th>
                            <th>HEARTS</th>
                            <th>VIDEOS</th>
                          </tr>
                        </template>
                        <template slot="tbody">
                          <template v-for="(row, index) in exportList">
                            <tr
                              v-if="row.social == social.id"
                              :key="index"
                              class="d-flex"
                              :style="{
                                width: tableHeaderWidth[social.id] + 'px'
                              }"
                            >
                              <th
                                :class="
                                  'checkbox-column ' +
                                    (includeExport[index]
                                      ? 'checkbox-column-checked'
                                      : '')
                                "
                              >
                                <base-checkbox
                                  v-model="includeExport[index]"
                                  class="custom-control-alternative"
                                  @change="checkProfile"
                                ></base-checkbox>
                              </th>
                              <td
                                @click="showProfile(row)"
                                class="name-column flex-middle"
                              >
                                <div class="avatar ml-2">
                                  <img
                                    :src="row.covers_small[0]"
                                    itemprop="image"
                                    :alt="row.nickname"
                                    :title="row.nickname"
                                    @error="onLogoImageError($event)"
                                  />
                                </div>
                                <div class="flex-column ml-2">
                                  <div class="profile-name">
                                    {{ row.nickname }}
                                  </div>
                                  <div class="profile-username">
                                    @{{ row.username }}
                                  </div>
                                </div>
                              </td>
                              <td class="space-column"></td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.fans | BigNumber }}
                              </td>
                              <td class="flex-middle-center">
                                {{
                                  row.avg_engagement_rate | Percent
                                }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.hearts | BigNumber }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.videos | BigNumber }}
                              </td>
                            </tr>
                          </template>
                        </template>
                      </VueScrollableTable>
                    </li>
                    <li class="panel-item field-block my-3">
                      <h3>Select which fields to export</h3>
                      <div class="field-wrapper p-3">
                        <div
                          v-for="(category, cIndex) in fieldCategories[social.id]"
                          :key="social.id + '#' + category.name"
                          class="category-block"
                        >
                          <div class="category d-flex py-2">
                            <a
                              :class="
                                'mr-3 category-collapse category-' +
                                  (category.open ? 'open' : 'close')
                              "
                              @click="toggleCategory(cIndex, social.id)"
                            >
                              <i class="fas fa-chevron-right"></i>
                            </a>
                            <base-checkbox
                              v-model="category.selected"
                              @change="updateTreeByCategory(cIndex, social.id)"
                              class="ml-2"
                              >{{ category.label }}</base-checkbox
                            >
                          </div>
                          <div
                            class="field-container"
                            :style="{
                              height: category.open
                                ? getNumberOfField(category, social.id) * 25 +
                                  'px'
                                : '0px'
                            }"
                          >
                            <div
                              v-for="field in fields"
                              :key="field.social + '#' + field.name"
                              class="field"
                            >
                              <div
                                v-if="
                                  field.category == category.name &&
                                    field.social == social.id
                                "
                                class="d-flex ml-5"
                                :style="{ height: '25px' }"
                              >
                                <div class="field-vertical-line mr-4"></div>
                                <base-checkbox
                                  v-model="field.selected"
                                  @change="updateTreeByField(cIndex, social.id)"
                                  >{{ field.label }}</base-checkbox
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
                <!-- youtube -->
                <template v-if="social.id == socials.youtube.id">
                  <ul class="pannel-blocks mt-5">
                    <li class="panel-item profile-block">
                      <h3>Review the YouTube channels you want to export</h3>

                      <VueScrollableTable
                        class="profile-export-table table-youtube"
                        :scroll-horizontal="true"
                        :scroll-vertical="true"
                        :sync-header-scroll="true"
                        :dead-area-color="'transparent'"
                        :class="{ freezeFirstColumn: true }"
                      >
                        <template slot="thead">
                          <tr>
                            <th
                              :class="
                                'checkbox-column ' +
                                  (enabledCheckAllProfiles
                                    ? 'checkbox-column-checked'
                                    : '')
                              "
                            >
                              <base-checkbox
                                v-model="enabledCheckAllProfiles"
                                class="custom-control-alternative"
                                @change="checkAllProfiles"
                              ></base-checkbox>
                            </th>
                            <th class="name-column">NAME</th>
                            <th>SUBSCRIBERS</th>
                            <th>ENGAGEMENT</th>
                            <th>POST LIKES</th>
                            <th>VIDEOS</th>
                          </tr>
                        </template>
                        <template slot="tbody">
                          <template v-for="(row, index) in exportList">
                            <tr
                              v-if="row.social == social.id"
                              :key="index"
                              class="d-flex"
                              :style="{
                                width: tableHeaderWidth[social.id] + 'px'
                              }"
                            >
                              <th
                                :class="
                                  'checkbox-column ' +
                                    (includeExport[index]
                                      ? 'checkbox-column-checked'
                                      : '')
                                "
                              >
                                <base-checkbox
                                  v-model="includeExport[index]"
                                  class="custom-control-alternative"
                                  @change="checkProfile"
                                ></base-checkbox>
                              </th>
                              <td
                                @click="showProfile(row)"
                                class="name-column flex-middle"
                              >
                                <div class="avatar ml-2">
                                  <img
                                    :src="row.avatar_url"
                                    itemprop="image"
                                    :alt="row.title"
                                    :title="row.title"
                                    @error="onLogoImageError($event)"
                                  />
                                </div>
                                <div class="flex-column ml-2">
                                  <div class="profile-name">
                                    {{ row.title }}
                                  </div>
                                  <div class="profile-username">
                                    @{{ row.id }}
                                  </div>
                                </div>
                              </td>
                              <td class="space-column"></td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.subscribers | BigNumber }}
                              </td>
                              <td class="flex-middle-center">
                                {{
                                  row.avg_engagement_rate | Percent
                                }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.avg_likes_pct | Percent }}
                              </td>
                              <td
                                class="flex-middle-center"
                              >
                                {{ row.videos | BigNumber }}
                              </td>
                            </tr>
                          </template>
                        </template>
                      </VueScrollableTable>
                    </li>
                    <li class="panel-item field-block my-3">
                      <h3>Select which fields to export</h3>
                      <div class="field-wrapper p-3">
                        <div
                          v-for="(category, cIndex) in fieldCategories[social.id]"
                          :key="social.id + '#' + category.name"
                          class="category-block"
                        >
                          <div class="category d-flex py-2">
                            <a
                              :class="
                                'mr-3 category-collapse category-' +
                                  (category.open ? 'open' : 'close')
                              "
                              @click="toggleCategory(cIndex, social.id)"
                            >
                              <i class="fas fa-chevron-right"></i>
                            </a>
                            <base-checkbox
                              v-model="category.selected"
                              @change="updateTreeByCategory(cIndex, social.id)"
                              class="ml-2"
                              >{{ category.label }}</base-checkbox
                            >
                          </div>
                          <div
                            class="field-container"
                            :style="{
                              height: category.open
                                ? getNumberOfField(category, social.id) * 25 +
                                  'px'
                                : '0px'
                            }"
                          >
                            <div
                              v-for="field in fields"
                              :key="field.social + '#' + field.name"
                              class="field"
                            >
                              <div
                                v-if="
                                  field.category == category.name &&
                                    field.social == social.id
                                "
                                class="d-flex ml-5"
                                :style="{ height: '25px' }"
                              >
                                <div class="field-vertical-line mr-4"></div>
                                <base-checkbox
                                  v-model="field.selected"
                                  @change="updateTreeByField(cIndex, social.id)"
                                  >{{ field.label }}</base-checkbox
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
              </tab-pane>
            </template>
          </tabs>

          <ul class="panel-blocks mt-5">
            <li class="panel-item field-block my-2 flex-middle">
              <h3 class="mt-3">Email:</h3>
              <base-input
                id="input_export_email"
                class="input-group-alternative ml-2 mb--2 w-100"
                :class="localError ? 'error' : ''"
                v-on:keyup.enter="exportProfiles(true)"
                placeholder="Email"
                v-model="email"
                type="text"
                autofocus
              ></base-input>
            </li>
            <li class="panel-item field-block my-2">
              <h3>Credits required: {{ credits / 100 | NormalNumber(2) }}</h3>
            </li>
            <li class="field-block mt-3 flex-center">
              <base-button
                type="primary"
                icon="fas fa-file-download"
                class="input-group-alternative"
                @click="exportProfiles(true)"
                >Export
                {{
                  includeExport.filter(value => {
                    return value;
                  }).length
                }}
                Influencer(s) Data</base-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getFullName, getEngagementRateClass, onLogoImageError } from "@/utils";
import {
  PROFILE_FIELDS,
  PROFILE_FIELD_CATEGORIES,
  ENGAGEMENT_CHART_DATA,
  SOCIALS,
  MESSAGES
} from "@/constants";

/* components */
import VueScrollableTable from "vue-scrolling-table";

export default {
  name: "export-panel",
  components: {
    VueScrollableTable
  },
  data() {
    return {
      getFullName: getFullName,
      getEngagementRateClass: getEngagementRateClass,
      socials: SOCIALS,
      includeExport: [],
      loading: {
        export: false,
        price: false
      },
      email: "",
      localError: null,
      fields: PROFILE_FIELDS,
      fieldsState: [],
      credits: 0,
      fieldCategories: {},
      engagementRateChartList: ENGAGEMENT_CHART_DATA,
      enabledCheckAllProfiles: true,
      tableHeaderWidth: [],
      resizeHandler: null,
    };
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    }
  },
  mounted() {
    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    for (const social in SOCIALS) {
      this.fieldCategories[social] = PROFILE_FIELD_CATEGORIES[social];
      this.fieldCategories[social].forEach(category => {
        category.open = true;
        category.selected = true;
      });
    }

    this.fields.forEach(field => {
      field.selected = true;
    });
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.user.authUser,
      exportList: state => state.influencer.exportList,
      showAlert: state => state.alert.showAlert
    })
  },
  methods: {
    onLogoImageError: onLogoImageError,
    closePanel() {
      document.querySelector(
        ".export-panel .modal-dialog"
      ).style.right = `-100%`;
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    showProfile(profile) {
      this.$emit("showProfile", profile);
    },
    getNumberOfField(category, social) {
      return this.fields.filter(
        field => category.name === field.category && social === field.social
      ).length;
    },
    showExportProfiles: function(val) {
      if (val) {
        this.includeExport = this.exportList.map(() => true);
      }
    },
    checkProfile() {
      this.enabledCheckAllProfiles =
        this.includeExport.filter(element => element).length ===
        this.exportList.length;
      this.getExportPrice();
    },
    checkAllProfiles() {
      this.includeExport = this.exportList.map(
        () => this.enabledCheckAllProfiles
      );
      this.getExportPrice();
    },
    clearAllProfiles() {
      this.$store.dispatch("influencer/clearExportList");
    },
    toggleCategory(cIndex, social) {
      const category = {
        name: this.fieldCategories[social][cIndex].name,
        label: this.fieldCategories[social][cIndex].label,
        selected: this.fieldCategories[social][cIndex].selected,
        open: !this.fieldCategories[social][cIndex].open
      };
      this.fieldCategories[social].splice(cIndex, 1, category);
      this.fieldCategories = { ...this.fieldCategories };
    },
    updateTreeByField(cIndex, social) {
      this.fieldCategories[social][cIndex].selected = this.fields.filter(
        field =>
          field.social === social &&
          field.category === this.fieldCategories[social][cIndex].name &&
          !field.selected
      ).length
        ? false
        : true;
      this.fieldCategories = { ...this.fieldCategories };
      this.getExportPrice();
    },
    updateTreeByCategory(cIndex, social) {
      this.fields.forEach(field => {
        if (
          this.fieldCategories[social][cIndex].name !== field.category ||
          social !== field.social
        )
          return;
        field.selected = this.fieldCategories[social][cIndex].selected;
      });
      this.fieldCategories = { ...this.fieldCategories };
      this.getExportPrice();
    },

    validateEmail(inputText) {
      // eslint-disable-next-line
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (inputText.match(mailformat)) {
        return true;
      } else {
        return false;
      }
    },
    hasSocialInExportList(social) {
      let hasSocial = false;
      this.exportList.forEach(profile => {
        if (profile.social === social) {
          hasSocial = true;
        }
      });
      return hasSocial;
    },
    getExportData() {
      let data = {};
      for (const social in SOCIALS) {
        const fields = this.fields
          .filter(e => e.selected && e.social === social)
          .map(e => {
            return e.name;
          });
        data[social] = {
          usernames: [],
          fields: fields
        };
      }

      this.exportList.forEach((profile, index) => {
        if (this.includeExport[index]) {
          data[profile.social].usernames.push(
            profile.social == SOCIALS.youtube ?
            profile.id :
            profile.username
          );
        }
      });
      return data;
    },
    getExportPriceData() {
      let exportData = this.getExportData();
      let data = {};
      for (const social in SOCIALS) {
        data[social] = {
          n: exportData[social].usernames.length,
          fields: [...exportData[social].fields]
        }
      }
      return data;
    },
    async getExportPrice() {
      const exportPriceData = this.getExportPriceData();

      this.loading.price = true;
      try {
        const { data } = await this.$store.dispatch(
          "influencer/getExportPrice",
          exportPriceData
        );
        this.credits = data.credits;
        this.loading.price = false;
      } catch {
        this.loading.price = true;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_export_price-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async exportProfiles() {
      const exportData = this.getExportData();
      this.loading.export = true;
      this.localError = this.validateEmail(this.email)
        ? null
        : "Please input correct email address.";

      if (this.localError) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.localError,
          alertClass: "danger"
        });
        return;
      }
      try {
        const result = await this.$store.dispatch("influencer/exportProfiles", {
          email: this.email,
          export: exportData
        });

        if (result) {
          this.clearAllProfiles();
          this.closePanel();
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__export_profiles-success"],
            alertClass: "success"
          });
        }
        this.loading.export = false;
      } catch {
        this.loading.export = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__export_profiles-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    resizeWindow() {
      setTimeout(() => {
        for (const social in SOCIALS) {
          const el = document.querySelector(
            `.table-${social}.profile-export-table thead tr`
          );
          this.tableHeaderWidth[social] = el ? el.clientWidth : 0;
        }
        this.tableHeaderWidth = {...this.tableHeaderWidth};
      }, 100);
    }
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        document.querySelector(
          ".export-panel .modal-dialog"
        ).style.right = `0px`;
        this.includeExport = this.exportList.map(() => {
          return true;
        });
        this.resizeWindow();
        this.getExportPrice();
        this.email = this.authUser.email;
      } else {
        documentClasses.remove("modal-open");
        document.querySelector(".export-panel").scrollTop = 0;
      }
    },
    email() {
      this.localError = null;
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
